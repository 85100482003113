import "./order-inspect.scss";
import { Order } from "../../../interfaces/backtester/BacktestTradingReport";
import { useCallback, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { OrderInspectChart } from "../../chart-components/interactive-chart/order-inspect-chart/OrderInspectChart";
import { Avatar } from "@mantine/core";
import { motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import { BacktesterService } from "../../../services/BacktesterService";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";
import { getAllowedTimeInterval } from "../../../utils/CandleCountUtil";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import {
  calcPctChange,
  determineDecimals,
  toUTCTimestring,
} from "../../../utils/FormattingUtils";
import CountUp from "react-countup";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";
import { IndicatorInfosContext } from "../../../shared-service-contexts/IndicatorsInfoContext";
import { ParsedCriteria } from "../../strategy-body/ParsedCriteria";
import { FiCheck, FiX } from "react-icons/fi";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { RiCodeSSlashLine } from "react-icons/ri";
import { useMediaQuery } from "@mantine/hooks";
import { EquationContext } from "react-equation";
import { CommonCandleSizeControl } from "../common-candle-size-control/CommonCandleSizeControl";

interface Props {
  activeTheme: string;
  buyOrder: Order | undefined;
  sellOrder: Order | undefined;
  closeModal: () => void;
}
export function OrderInspect(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const mobile = useMediaQuery("(max-width: 768px)");
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const currencies = useContext(CurrencyContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const [showEntryReason, setShowEntryReason] = useState(!mobile);
  const [showExitReason, setShowExitReason] = useState(!mobile);

  const getDeterminedCandleSizeFromHoldingPeriod = useCallback(
    (buyTimestamp?: number | string, sellTimestampStr?: string) => {
      buyTimestamp = parseInt(buyTimestamp as string);
      let candleSize = "1D";
      if (!buyTimestamp || !sellTimestampStr) return candleSize;
      const sellTimestamp = parseInt(sellTimestampStr);
      const holdingPeriodDays =
        (sellTimestamp - buyTimestamp) / (1000 * 60 * 60 * 24);
      if (holdingPeriodDays < 3) {
        candleSize = "1h";
        const holdingPeriodHours =
          (sellTimestamp - buyTimestamp) / (1000 * 60 * 60);
        if (holdingPeriodHours < 3) {
          candleSize = "5m";
        }
      }
      return candleSize;
    },
    []
  );

  const [candleSize, setCandleSize] = useState(() =>
    getDeterminedCandleSizeFromHoldingPeriod(
      props.buyOrder?.acquiring_timestamp,
      props.sellOrder?.selling_timestamp
    )
  );

  const now = useMemo(() => new Date().getTime(), []);

  const interval = useMemo(() => {
    const basePadding = 10;
    const maxPadding =
      candleSize === "1D"
        ? 30
        : candleSize === "1h"
        ? basePadding * 24
        : basePadding * 24 * 5; // candles
    const increment =
      candleSize === "1D"
        ? 60 * 60 * 24 * 1000
        : candleSize === "1h"
        ? 60 * 60 * 1000
        : 60 * 5 * 1000;
    const sellTimestamp = props.sellOrder
      ? props.sellOrder.timestamp_references[`candle_size_${candleSize}`]
      : undefined;
    const buyTimestamp =
      props.buyOrder?.timestamp_references[`candle_size_${candleSize}`] ||
      sellTimestamp;

    let allowedCandleAmount = 0;
    let currentTimestamp = buyTimestamp;
    while (currentTimestamp < sellTimestamp) {
      allowedCandleAmount++;
      currentTimestamp += increment;
    }

    const centerTimestamp = !sellTimestamp
      ? buyTimestamp
      : // get the timestamp in the middle of the buy and sell timestamps
        (buyTimestamp + sellTimestamp) / 2;
    const _interval = getAllowedTimeInterval(
      now,
      allowedCandleAmount + maxPadding,
      candleSize,
      centerTimestamp
    );
    return { start: _interval[0], stop: _interval[1] };
  }, [props.buyOrder, props.sellOrder, candleSize, now]);

  const orderCurrencyName = useMemo(() => {
    if (props.buyOrder) {
      return props.buyOrder.currency_name;
    }
    return props.sellOrder?.currency_name;
  }, [props.buyOrder, props.sellOrder]);

  const fetchCandles = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;

    if (!interval || !candleSize || !orderCurrencyName) return;
    const response = await BacktesterService.blueprint.getCandles(
      token,
      orderCurrencyName,
      candleSize,
      interval.start,
      interval.stop
    );
    return response.data;
  }, [getAccessTokenSilently, interval, candleSize, orderCurrencyName]);

  const candlesQuery = useQuery(
    ["OrderInspectCandles", interval],
    fetchCandles,
    {
      cacheTime: 60 * 5 * 100,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const [chartContainerElement, setChartContainerElement] = useState<
    HTMLDivElement | null | undefined
  >();

  const currencyUrl = useMemo(() => {
    const currency = currencies?.find(
      (currency) => currency.currency_name === orderCurrencyName
    );
    return currency ? getCurrencyUrl(currency) : undefined;
  }, [currencies, orderCurrencyName]);

  const buyTimestring = useMemo(() => {
    if (!props.buyOrder) return;
    const livetraderTimestamp =
      props.buyOrder.live_execution_timestamp &&
      parseInt(props.buyOrder.live_execution_timestamp);
    const acquiringTimestamp =
      props.buyOrder.acquiring_timestamp &&
      parseInt(props.buyOrder.acquiring_timestamp);
    const timestamp =
      livetraderTimestamp ||
      acquiringTimestamp ||
      props.buyOrder.timestamp_references.candle_size_5m;
    return toUTCTimestring(timestamp, "1h");
  }, [props.buyOrder]);

  const sellTimestring = useMemo(() => {
    if (!props.sellOrder) return;
    const livetraderTimestamp =
      props.sellOrder.live_execution_timestamp &&
      parseInt(props.sellOrder.live_execution_timestamp);
    const sellingTimestamp =
      props.sellOrder.selling_timestamp &&
      parseInt(props.sellOrder.selling_timestamp);
    const timestamp =
      livetraderTimestamp ||
      sellingTimestamp ||
      props.sellOrder.timestamp_references.candle_size_5m;
    return toUTCTimestring(timestamp, "1h");
  }, [props.sellOrder]);

  const { dollarValueDifference, pctValueDifference } = useMemo(() => {
    if (!props.buyOrder || !props.sellOrder || !props.sellOrder.sell_price)
      return {
        dollarValueDifference: undefined,
        pctValueDifference: undefined,
      };
    let tradeQuantity = props.buyOrder.quantity;
    if (props.sellOrder.quantity < tradeQuantity) {
      tradeQuantity = props.sellOrder.quantity;
    }
    const buyValue = props.buyOrder.buy_price * tradeQuantity;
    const sellValue = props.sellOrder.sell_price * tradeQuantity;
    const dollarValueDifference = sellValue - buyValue;

    const pctValueDifference = calcPctChange(buyValue, sellValue);
    return { dollarValueDifference, pctValueDifference };
  }, [props.buyOrder, props.sellOrder]);

  const { data } = usePalette(currencyUrl ?? "");
  const paletteData = useMemo(() => {
    return data;
  }, [data]);

  const entryTradeReason = useMemo(() => {
    if (!props.buyOrder || !props.buyOrder?.entry_criteria) return;
    const candle_sizes = ["1D", "1h", "5m"];
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="reason-wrapper"
      >
        {candle_sizes.map((_candle_size) => {
          const candle_size_criteria = props.buyOrder?.entry_criteria[
            `candle_size_${_candle_size}`
          ] as string[];
          if (!candle_size_criteria || candle_size_criteria.length === 0)
            return;
          return (
            <div
              key={_candle_size}
              className="criteria-candle-size-reason-container entry"
            >
              <label>{_candle_size} criteria</label>
              {candle_size_criteria && candle_size_criteria.length > 0
                ? candle_size_criteria.map((criterion, idx) => {
                    return (
                      <div key={idx} className="candle-size-reason-row">
                        <FiCheck />
                        <ParsedCriteria
                          activeTheme={props.activeTheme}
                          criteria={criterion}
                          indicatorsInfo={indicatorsInfo}
                        />
                      </div>
                    );
                  })
                : undefined}
            </div>
          );
        })}
      </motion.div>
    );
  }, [props.buyOrder, props.activeTheme, indicatorsInfo]);

  const exitTradeReason = useMemo(() => {
    if (
      !props.sellOrder?.exit_criteria &&
      !props.sellOrder?.immediate_sell_action
    )
      return;
    if (props.sellOrder.immediate_sell_action) {
      const immediateAction = props.sellOrder.immediate_sell_action;
      const immediateActionType =
        immediateAction.type === "stoploss" ? "Stoploss" : "Take profit";
      const immediateActionSubtypeLabel =
        immediateAction.subtype === "trailing_atr"
          ? "Trailing ATR"
          : immediateAction.subtype === "atr"
          ? "ATR"
          : immediateAction.subtype === "disabled"
          ? ""
          : immediateAction.subtype;
      const changeDirectionLabel =
        immediateAction.type === "stoploss" ? "↓ " : "↑ ";

      const atrBasePriceLabel =
        immediateAction.subtype === "trailing_atr"
          ? "Peak Price"
          : immediateAction.subtype === "atr"
          ? "Buy Price"
          : undefined;

      const atrStoplossEquation = `Target Price = ${atrBasePriceLabel} - (ATR * ${immediateAction.atr_multiplier})`;
      const atrTakeProfitEquation = `Target Price = Buy Price + (ATR * ${immediateAction.atr_multiplier})`;
      const atrStoplossEquationSimple = `Current Price < Target Price`;
      const atrTakeProfitEquationSimple = `Current Price > Target Price`;
      const atrStoplossEquationActual = `${
        immediateAction.atr_base_price
      } - (${immediateAction.atr_value?.toFixed(2)} * ${
        immediateAction.atr_multiplier
      })`;
      const atrTakeProfitEquationActual = `${
        immediateAction.atr_base_price
      } + (${immediateAction.atr_value?.toFixed(2)} * ${
        immediateAction.atr_multiplier
      })`;

      const atrEquationSimple = atrBasePriceLabel ? (
        <div className="immediate-action-reason-row">
          <label className="dimmed-label">Triggered on:</label>
          <EquationContext
            render={(renderEquation) => (
              <div className="equation-wrapper-smaller">
                {immediateAction.type === "stoploss"
                  ? renderEquation(atrStoplossEquationSimple)
                  : renderEquation(atrTakeProfitEquationSimple)}
              </div>
            )}
          />
        </div>
      ) : undefined;
      const atrEquation = atrBasePriceLabel ? (
        <div className="immediate-action-reason-row">
          <EquationContext
            render={(renderEquation) => (
              <div className="equation-wrapper-smaller">
                {immediateAction.type === "stoploss"
                  ? renderEquation(atrStoplossEquation)
                  : renderEquation(atrTakeProfitEquation)}
              </div>
            )}
          />
        </div>
      ) : undefined;

      const atrEquationActual = atrBasePriceLabel ? (
        <div className="immediate-action-reason-row">
          <label className="dimmed-label">Target Price:</label>

          <EquationContext
            render={(renderEquation) => (
              <div className="equation-wrapper-smaller">
                {immediateAction.type === "stoploss"
                  ? renderEquation(atrStoplossEquationActual)
                  : renderEquation(atrTakeProfitEquationActual)}
              </div>
            )}
          />

          {immediateAction.type === "stoploss" &&
          immediateAction.stoploss_target_price ? (
            <>
              =
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.stoploss_target_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(
                  immediateAction.stoploss_target_price
                )}
              />
            </>
          ) : immediateAction.take_profit_target_price ? (
            <>
              =
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.take_profit_target_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(
                  immediateAction.take_profit_target_price
                )}
              />
            </>
          ) : undefined}
        </div>
      ) : undefined;

      const configurationComponent = (
        <>
          {immediateAction.atr_multiplier !== undefined ? (
            atrEquation
          ) : immediateAction.configured_trigger_pct ? (
            <CountUp
              className={"pool-funds-label value"}
              end={immediateAction.configured_trigger_pct}
              start={0}
              duration={1}
              prefix={changeDirectionLabel}
              suffix=" %"
              decimals={2}
            />
          ) : null}
        </>
      );

      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`reason-wrapper immediate-action-reason-container ${immediateAction.type}`}
        >
          <div className="immediate-action-reason-row">
            <label className="immediate-action-type-label">
              {immediateActionSubtypeLabel}
            </label>
            <label className="immediate-action-type-label">
              {immediateActionType}
            </label>
            |{configurationComponent}
          </div>

          {!atrEquation && immediateAction.take_profit_target_price ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">TP target price:</label>
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.take_profit_target_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(
                  immediateAction.take_profit_target_price
                )}
              />
            </div>
          ) : undefined}

          {!atrEquation && immediateAction.current_price ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">Trigger price:</label>
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.current_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(immediateAction.current_price)}
              />
            </div>
          ) : undefined}
          {!atrEquation && immediateAction.change_pct ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">Trigger pct:</label>
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.change_pct}
                start={0}
                duration={1}
                suffix=" %"
                decimals={2}
              />
            </div>
          ) : undefined}
          {!atrEquation && immediateAction.stoploss_target_price ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">SL target price:</label>
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.stoploss_target_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(
                  immediateAction.stoploss_target_price
                )}
              />
            </div>
          ) : undefined}
          {atrEquationSimple}

          {atrEquationActual}
          {atrEquationActual && immediateAction.current_price ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">Current Price:</label>
              <CountUp
                className={"pool-funds-label value"}
                end={immediateAction.current_price}
                start={0}
                duration={1}
                prefix="$ "
                decimals={determineDecimals(immediateAction.current_price)}
              />
            </div>
          ) : undefined}
          {immediateAction.candle_size ? (
            <div className="immediate-action-reason-row">
              <label className="dimmed-label">Reaction candle:</label>
              <label>{immediateAction.candle_size}</label>
            </div>
          ) : undefined}
        </motion.div>
      );
    } else {
      const candle_sizes = ["1D", "1h", "5m"];
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="reason-wrapper"
        >
          {candle_sizes.map((_candle_size) => {
            const candle_size_criteria =
              props.sellOrder?.exit_criteria?.[`candle_size_${_candle_size}`];
            if (!candle_size_criteria || candle_size_criteria.length === 0)
              return;
            return (
              <div
                key={_candle_size}
                className="criteria-candle-size-reason-container exit"
              >
                <label>{_candle_size} criteria</label>
                {candle_size_criteria.map((criterion, idx) => {
                  return (
                    <div key={idx} className="candle-size-reason-row">
                      <FiCheck />
                      <ParsedCriteria
                        activeTheme={props.activeTheme}
                        criteria={criterion}
                        indicatorsInfo={indicatorsInfo}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </motion.div>
      );
    }
  }, [props.sellOrder, props.activeTheme, indicatorsInfo]);

  const entryValue = useMemo(() => {
    if (!props.buyOrder) return;
    const buyQuantity = props.buyOrder.quantity;
    return props.buyOrder.buy_price * buyQuantity;
  }, [props.buyOrder]);

  const exitValue = useMemo(() => {
    if (!props.buyOrder || !props.sellOrder || !props.sellOrder.sell_price)
      return;
    const sellQty = props.sellOrder.quantity || props.buyOrder.quantity;
    return props.sellOrder.sell_price * sellQty;
  }, [props.sellOrder, props.buyOrder]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="order-inspect-container"
    >
      {props.buyOrder?.case && (
        <div className="order-inspect-case">
          <RiCodeSSlashLine size={22} />
          <label>{props.buyOrder?.case}</label>
          {mobile && (
            <FiX
              onClick={props.closeModal}
              style={{ marginLeft: "auto" }}
              size={22}
            />
          )}
        </div>
      )}
      <div className="trade-stats-container">
        {props.buyOrder ? (
          <motion.div
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="buy-order-stat-container"
          >
            <div className="order-stat-row">
              <label>Bought {props.buyOrder?.currency_name} </label>
              <label>{buyTimestring}</label>
            </div>
            {props.buyOrder?.buy_price && (
              <div className="order-stat-row">
                <label>Buy price</label>
                <CountUp
                  className={"pool-funds-label value"}
                  end={props.buyOrder.buy_price}
                  start={0}
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(props.buyOrder.buy_price)}
                />
              </div>
            )}
            <div className="order-stat-row">
              <label>Quantity</label>
              <label>{props.buyOrder?.quantity}</label>
            </div>
            {props.buyOrder?.filled !== undefined && (
              <div className="order-stat-row">
                <label>Filled</label>
                <label>{props.buyOrder?.filled}</label>
              </div>
            )}
            {props.buyOrder?.remaining !== undefined && props.buyOrder?.remaining !== 0 && (
              <div className="order-stat-row">
                <label>Remaining</label>
                <label>{props.buyOrder?.remaining}</label>
              </div>
            )}
            {entryValue && (
              <div className="order-stat-row">
                <label>Entry value</label>
                <CountUp
                  className={"pool-funds-label value"}
                  end={entryValue}
                  start={0}
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(entryValue)}
                />
              </div>
            )}

            <div className="trade-reason-container">
              <label
                onClick={() => setShowEntryReason(!showEntryReason)}
                className="reason-header"
              >
                {showEntryReason ? (
                  <FaChevronDown color={theme.buyOrderStrokeHover} />
                ) : (
                  <FaChevronRight color={theme.buyOrderStrokeHover} />
                )}
                Entry reason
                {showEntryReason ? (
                  <label
                    style={{ marginLeft: "auto" }}
                    className="dimmed-label"
                  >
                    Click to collapse
                  </label>
                ) : (
                  <label
                    style={{ marginLeft: "auto" }}
                    className="dimmed-label"
                  >
                    Click to unfold
                  </label>
                )}
              </label>
              {props.buyOrder && showEntryReason && entryTradeReason}
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="buy-order-stat-container"
          >
            <label>
              Buy order not found.
            </label>
          </motion.div>
        )}

        {props.sellOrder && (
          <motion.div
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="sell-order-stat-container"
          >
            <div className="order-stat-row">
              <label>Sold {props.sellOrder?.currency_name}</label>
              <label>{sellTimestring}</label>
            </div>
            {props.sellOrder?.sell_price && (
              <div className="order-stat-row">
                <label>Sell price</label>
                <CountUp
                  className={"pool-funds-label value"}
                  end={props.sellOrder.sell_price}
                  start={0}
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(props.sellOrder.sell_price)}
                />
              </div>
            )}
            <div className="order-stat-row">
              <label>Quantity</label>
              <label>{props.sellOrder?.quantity ?? props.buyOrder?.quantity}</label>
            </div>

            {props.sellOrder?.filled !== undefined && (
              <div className="order-stat-row">
                <label>Filled</label>
                <label>{props.sellOrder?.filled}</label>
              </div>
            )}
            {props.sellOrder?.remaining !== undefined && props.sellOrder.remaining !== 0 && (
              <div className="order-stat-row">
                <label>Remaining</label>
                <label>{props.sellOrder?.remaining}</label>
              </div>
            )}

            {exitValue && (
              <div className="order-stat-row">
                <label>Exit value</label>
                <CountUp
                  className={"pool-funds-label value"}
                  end={exitValue}
                  start={0}
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(exitValue)}
                />
              </div>
            )}
            <div className="trade-reason-container">
              <label
                onClick={() => setShowExitReason(!showExitReason)}
                className="reason-header"
              >
                {showExitReason ? (
                  <FaChevronDown color={theme.sellOrderStrokeHover} />
                ) : (
                  <FaChevronRight color={theme.sellOrderStrokeHover} />
                )}
                Exit reason
                {showExitReason ? (
                  <label
                    style={{ marginLeft: "auto" }}
                    className="dimmed-label"
                  >
                    Click to collapse
                  </label>
                ) : (
                  <label
                    style={{ marginLeft: "auto" }}
                    className="dimmed-label"
                  >
                    Click to unfold
                  </label>
                )}
              </label>
              {props.sellOrder && showExitReason && exitTradeReason}
            </div>
          </motion.div>
        )}
      </div>

      <div
        style={{
          alignSelf: "flex-start",
          width: "100%",
        }}
        className="candle-size-control-container"
      >
        <motion.div
          className="currency-accent"
          style={{ backgroundColor: paletteData?.vibrant }}
        />
        <Avatar src={currencyUrl} size={"sm"} radius={"lg"} />

        <CommonCandleSizeControl
          selectedCandleSize={candleSize}
          onClick={setCandleSize}
          hideLabel
        />

        {pctValueDifference && (
          <>
            <CountUp
              style={{
                marginLeft: "auto",
                marginRight: "10px",
                fontSize: mobile ? "smaller" : "larger",
              }}
              className={
                pctValueDifference === 0
                  ? "wallet-stat-label"
                  : pctValueDifference > 0
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              end={pctValueDifference}
              start={0}
              duration={1}
              prefix={pctValueDifference > 0 ? "+ " : ""}
              suffix={" %"}
              decimals={2}
            />
          </>
        )}
        {dollarValueDifference && (
          <>
            <CountUp
              style={{
                marginRight: "10px",
                fontSize: mobile ? "smaller" : "larger",
              }}
              className={
                dollarValueDifference === 0
                  ? "wallet-stat-label"
                  : dollarValueDifference > 0
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              end={dollarValueDifference}
              start={0}
              duration={1}
              prefix={dollarValueDifference > 0 ? "+$ " : "$ "}
              decimals={determineDecimals(dollarValueDifference)}
            />
            <motion.div
              className="trade-accent"
              style={{
                backgroundColor:
                  dollarValueDifference > 0
                    ? theme.buyOrderAreaY
                    : theme.sellOrderAreaY,
              }}
            />
          </>
        )}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, delay: 0.2 }}
        className="order-inspect-body"
        ref={(element) => {
          setChartContainerElement(element);
        }}
      >
        {candlesQuery.data && orderCurrencyName ? (
          <OrderInspectChart
            candleSize={candleSize}
            activeTheme={props.activeTheme}
            priceData={{
              currencyName: orderCurrencyName,
              prices: candlesQuery.data.candleData.closePrice,
              timestamps: candlesQuery.data.candleData.timestamps,
            }}
            chartContainerElement={chartContainerElement}
            buyOrder={props.buyOrder}
            sellOrder={props.sellOrder}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <NeotonSpinner activeTheme={props.activeTheme} />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}
