import "./backtest-button.scss";
import { motion } from "framer-motion";
import { useContext, useEffect, useMemo, useState } from "react";
import { usePalette } from "react-palette";
import { Avatar } from "@mantine/core";
import { getTheme } from "../../../utils/themeUtil";
import {
  StageableContext,
  StageBacktestContext,
  IsBacktestingContext,
  SetCancelBacktestContext,
  CancelBacktestContext,
} from "../../../pages/backtester-page/BacktesterPage";
import { GetBacktestStageRequestContext } from "../../../pages/backtester-page/context/BacktestStageRequestContext";
import crossLight from "../../Animations/cross-light.json";
import crossDark from "../../Animations/cross-dark.json";
import Lottie from "react-lottie";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { Currency } from "../../../interfaces/Currency";
import { getCurrencyUrl } from "../../../utils/cdnUtil";

interface Props {
  activeTheme: string;
  currencyNamesToBeTested: string[];
  isRunning: boolean;
}
export function BacktestButton(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const currencies = useContext(CurrencyContext);
  const stageable = useContext(StageableContext);
  const stageBacktest = useContext(StageBacktestContext);
  const backtestStageRequest = useContext(GetBacktestStageRequestContext);
  const setCancelBacktest = useContext(SetCancelBacktestContext);
  const cancellingBacktest = useContext(CancelBacktestContext);
  const isRunning = useContext(IsBacktestingContext);
  const [hover, setHover] = useState(false);

  const currencyNameMap = useMemo(() => {
    if (!currencies) return {};
    const _currenciesMap: { [key: string]: Currency } = {};
    currencies.forEach((currency) => {
      _currenciesMap[currency.currency_name] = currency;
    });
    return _currenciesMap;
  }, [currencies]);

  const initialCurrency = useMemo(() => {
    let initialCurrency = undefined;
    if (props.currencyNamesToBeTested.length === 0) return initialCurrency;
    return currencyNameMap[props.currencyNamesToBeTested[0]];
  }, [currencyNameMap, props.currencyNamesToBeTested]);

  const playButtonSize = "80";

  const [currencyUrl, setCurrencyUrl] = useState(
    initialCurrency
      ? getCurrencyUrl(initialCurrency)
      : "/assets/neoton/neotonLogo.png"
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        if (props.currencyNamesToBeTested.length > 0) {
          const nextCurrencyName = props.currencyNamesToBeTested[currentIndex];
          const nextCurrency = currencyNameMap[nextCurrencyName];
          const randomUrl = getCurrencyUrl(nextCurrency);
          setCurrencyUrl(randomUrl);
          setCurrentIndex((prev) => {
            if (prev + 1 < props.currencyNamesToBeTested.length) {
              return prev + 1;
            } else {
              return 0;
            }
          });
        }
      }, 4000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [
    setCurrencyUrl,
    isRunning,
    setCurrentIndex,
    currentIndex,
    props.currencyNamesToBeTested,
    currencyNameMap,
  ]);

  const { data } = usePalette(currencyUrl);
  const root = document.documentElement;
  root?.style.setProperty("--ring-color", data.vibrant ?? "#fff");

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: props.activeTheme === "light" ? crossLight : crossDark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      className="backtest-button-container"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {isRunning ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="loader-container"
          onClick={() => {
            setCancelBacktest(true);
          }}
        >
          {hover ? (
            <div className="cancel-button-container">
              <label className="cancel-button-label">Abort</label>
              <Lottie options={animationOptions} />
            </div>
          ) : (
            data.vibrant && <Avatar src={currencyUrl} radius={"xl"} />
          )}
        </motion.div>
      ) : (
        <>
          {stageable && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
              style={{ borderRadius: "50%" }}
              onClick={() => {
                if (cancellingBacktest) return;
                if (!backtestStageRequest) return;
                stageBacktest(backtestStageRequest);
              }}
            >
              <svg
                width={playButtonSize}
                height={playButtonSize}
                viewBox="0 0 131 131"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="inner-circle"
                  d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z"
                  fill={theme.text}
                ></path>
                <circle
                  className="outer_circle"
                  cx="65.5"
                  cy="65.5"
                  r="64"
                  stroke={theme.cardInterior}
                ></circle>
                <path
                  className="play"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M60 76V57L77 66.7774L60 76Z"
                  fill={theme.card}
                ></path>
              </svg>
            </motion.div>
          )}
        </>
      )}
    </motion.div>
  );
}
